<template>
	<div class="work">
		<div class="home_center">
			<div class="">
				<div class="center_left">
					<div class="left_top">
						<div class="top_name" style="cursor: pointer;" @click="back">
							<i class="el-icon-arrow-left"></i><!-- 返回 -->{{$t('home.FH')}}
						</div>
						<div class="flex-item"></div>
					</div>
					<div class="left_box">
						<div class="left_left">
							
								<el-button class="btn2" @click="link(1)" type="primary"><!-- 待开始 -->{{$t('job.DKS')}}</el-button>
						
								<el-button class="btn2" @click="link(2)"  type="primary"><!-- 进行中 -->{{$t('job.JXZ')}}</el-button>
							
								<el-button class="btn2"  @click="link(3)" type="primary"><!-- 已完成 -->{{$t('job.YWC')}}</el-button>
							
							<el-button class="mt20 btn1"  type="primary">{{$t('task.BJGZ')}}</el-button>
							
						</div>
						<div class="left_center">
							<div class="add_work">
								
								<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
									<el-form-item :label="$t('task.GZLM')" prop="cate_first_id">
										<el-cascader style="width: 100%;"
											:value="[ruleForm.cate_first_id,ruleForm.cate_sec_id,ruleForm.cate_third_id]"
										    :options="cateList"
										    @change="select_cate"></el-cascader>
									</el-form-item>
									<el-form-item :label="$t('task.GZMC')" prop="job_name">					
										<el-input v-model="ruleForm.job_name" maxlength="1000" show-word-limit></el-input>
									</el-form-item>
									<el-form-item :label="$t('job.SXYWNL')" prop="skills">
										<el-tag	v-for="(tag,index) in ruleForm.skills" :key="tag" @close="ruleForm.skills.splice(index,1)" closable effect="plain" style="margin-right: 10px;">{{tag}}</el-tag>
										<!-- <el-cascader @change="selectSkill" v-model="ruleForm.skills" :options="skillList" :show-all-levels="false" :props="{multiple:false,value:'label'}" style="width: 300px;"></el-cascader> -->
										<!-- <el-dropdown  @command="selectSkill" :hide-on-click="false" >
										  <el-button type="primary" size="mini">
										   {{$t('QXZ')}}<i class="el-icon-arrow-down el-icon--right"></i>
										  </el-button>
										  <el-dropdown-menu slot="dropdown">
											<template v-for="(item,index) in skillList" >
												<template v-if="item.children.length>0">
													<el-dropdown-item disabled :divided="index>0">{{item.label}}</el-dropdown-item>
													<el-dropdown-item :command="sitem.label" v-for="(sitem,sindex) in item.children" :key="sitem.value">{{sitem.label}}</el-dropdown-item>
												</template>
											</template>
										  </el-dropdown-menu>
										</el-dropdown> -->
										<el-button type="primary" size="mini" @click="openSkill">
										  {{$t('QXZ')}}<i class="el-icon-arrow-down el-icon--right"></i>
										</el-button>
									</el-form-item>
									
									<el-form-item :label="$t('job.GZGS')" prop="job_belong_method">
										<el-radio-group v-model="ruleForm.job_belong_method">
											
											<el-radio :label="item.data_code/1" v-for="item in jobBelong" :key="item.data_code">{{item.data_value}}</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item :label="$t('SFWTMC')" prop="entrust_name" v-if="ruleForm.job_belong_method==1">
										<div style="display: flex;align-items: center;">
											<el-input v-model="ruleForm.entrust_name"></el-input>
										</div>
									</el-form-item>
									<el-form-item :label="$t('job.JCFS')" prop="pay_method">
										<el-radio-group v-model="ruleForm.pay_method">											
											<el-radio :label="item.data_code/1" v-for="item in payMethod" :key="item.data_code">{{item.data_value}}</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item :label="$t('job_detail.BC')" prop="salary_high" v-if="ruleForm.pay_method==1">
										<div style="display: flex;align-items: center;">
										<el-input v-model="ruleForm.salary_high" :placeholder="$t('QSR')"></el-input>
										<el-select v-model="ruleForm.salary_unit" :placeholder="$t('QXZ')" style="margin-left:10px;">
											<el-option v-for="item in salaryUnit" :key="item.data_code" :label="item.data_value"
												:value="item.data_value">
											</el-option>
										</el-select>
										</div>
									</el-form-item>
									<template v-if="ruleForm.pay_method==0">
									
									<el-form-item :label="$t('SXFW')+'('+$t('LOW')+')'" prop="salary_low">
										<div style="display: flex;align-items: center;">
										<el-input v-model="ruleForm.salary_low" type="number" :placeholder="$t('QSR')"></el-input>
										<el-select v-model="ruleForm.salary_unit" :placeholder="$t('QXZ')" style="margin-left:10px;">
											<el-option v-for="item in salaryUnit" :key="item.data_code" :label="item.data_value"
												:value="item.data_value">
											</el-option>
										</el-select>
										</div>
									</el-form-item>
									<el-form-item :label="$t('SXFW')+'('+$t('HIGH')+')'" prop="salary_high">
										<div style="display: flex;align-items: center;">
										<el-input v-model="ruleForm.salary_high" type="number" :placeholder="$t('QSR')"></el-input>
										<el-select v-model="ruleForm.salary_unit" :placeholder="$t('QXZ')" style="margin-left:10px;">
											<el-option v-for="item in salaryUnit" :key="item.data_code" :label="item.data_value"
												:value="item.data_value">
											</el-option>
										</el-select>
										</div>
									</el-form-item>
									</template>
									<!-- <el-form-item :label="$t('job.GZSJ')" prop="oper_time_method">
										<el-radio-group v-model="ruleForm.oper_time_method" @change="ruleForm.oper_time_val=''">
											<el-radio :label="item.data_code/1" v-for="item in operTime" :key="item.data_code">{{item.data_value}}</el-radio>
										</el-radio-group>										
									</el-form-item>
									<el-form-item label="" prop="oper_time_val" v-if="ruleForm.oper_time_method==0">
										<el-input v-model="ruleForm.oper_time_val" style="width:100px"></el-input>
										<span style="margin-left: 10px;">/{{$t('TIAN')}}</span>
									</el-form-item>
									<el-form-item label="" prop="oper_time_val" v-if="ruleForm.oper_time_method==1">
										<el-date-picker
										      v-model="ruleForm.oper_time_val"
										      type="datetimerange"
											  value-format="yyyy-MM-dd HH:mm"
										       :start-placeholder="$t('KSRQ')"
										    :end-placeholder="$t('JSRQ')">
										    </el-date-picker>
									</el-form-item> -->
									
									<el-form-item :label="$t('job.GZSJ')" prop="oper_time_val">
										
										<el-input v-model="ruleForm.oper_time_val" type="number" style="width:150px"></el-input>
										
										<el-select v-model="ruleForm.oper_time_method" :placeholder="$t('QXZ')" style="margin-left:10px;width:100px">
											<el-option v-for="item in timeUnit" :key="item.data_code" :label="item.data_value"
												:value="item.data_code">
											</el-option>
										</el-select>
									</el-form-item>
									
									<!-- <el-form-item :label="$t('job.CQSJ')" prop="job_time_method">
										<el-radio-group v-model="ruleForm.job_time_method">
											<el-radio :label="item.data_code/1" v-for="item in jobTime" :key="item.data_code">{{item.data_value}}</el-radio>
										</el-radio-group>
										
									</el-form-item>
									<el-form-item label="" prop="job_time_val">
										<el-input v-model="ruleForm.job_time_val" style="width:100px"></el-input>
										<span style="margin-left: 10px;">{{$t('my_msg.XS')}}</span>
									</el-form-item> -->
									<el-form-item :label="$t('job.GZDD')" prop="job_location_method">
										<el-radio-group v-model="ruleForm.job_location_method">
											<el-radio :label="item.data_code/1" v-for="item in jobLocation" :key="item.data_code">{{item.data_value}}</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item :label="$t('my_msg.DDFX')" prop="cityname" v-if="ruleForm.job_location_method==0">
										<div style="display: flex;align-items: center;">
											<!-- <el-input v-model="ruleForm.name"></el-input> -->
											<!-- <template v-if="lang=='zh'">
											<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'name',value:'name'}" :placeholder="$t('QXZ')"></el-cascader>
											</template>
											<template v-else> -->
											<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'label',value:'label'}" :placeholder="$t('QXZ')"></el-cascader>
											<!-- </template> -->
											<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('task.FGK')}}</el-tag>
										</div>
									</el-form-item>
									<!-- <el-form-item :label="$t('task.DWDZ')" prop="location">
										<div style="display: flex;align-items: center;">
											<el-input v-model="ruleForm.location"></el-input>
										</div>
									</el-form-item> -->
									<el-form-item :label="$t('my_msg.XXDD')" prop="address">
										<div style="display: flex;align-items: center;">
											<el-input v-model="ruleForm.address" placeholder=""></el-input>
										</div>
									</el-form-item>
									<el-form-item :label="$t('job.GZFS')" prop="job_method">
										<el-radio-group v-model="ruleForm.job_method">
											<el-radio :label="item.data_code/1" v-for="item in jobMethod" :key="item.data_code">{{item.data_value}}</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item :label="$t('job.SFCC')" prop="trip_method">
										<el-radio-group v-model="ruleForm.trip_method">
											
											<el-radio :label="item.data_code/1" v-for="item in tripMethod" :key="item.data_code">{{item.data_value}}</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item :label="$t('job.XQRS')" type="number" prop="job_num_people">
										<el-input v-model="ruleForm.job_num_people"></el-input>
									</el-form-item>
									<el-form-item :label="$t('job.SFJP')" prop="fast_method">
										<el-radio-group v-model="ruleForm.fast_method">
											<el-radio :label="item.data_code/1" v-for="item in fastMethod" :key="item.data_code">{{item.data_value}}</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item :label="$t('job.GZXX')" prop="job_nature">
										<el-checkbox-group v-model="ruleForm.job_nature">
										    <el-checkbox :label="$t('job.FY')"></el-checkbox>
										  </el-checkbox-group>
									</el-form-item>
									<el-form-item :label="$t('job.JZRQ')" prop="job_end_time">
										<el-date-picker
										      v-model="ruleForm.job_end_time"
										      type="date"
											  value-format="yyyy-MM-dd"
										      :placeholder="$t('QXZ')">
										    </el-date-picker>
									</el-form-item>
									<el-form-item :label="$t('job.XGWJ')" prop="download_method">
										<el-radio-group v-model="ruleForm.download_method">
											<el-radio :label="item.data_code/1" v-for="item in downloadMethod" :key="item.data_code">{{item.data_value}}</el-radio>
										</el-radio-group>
									</el-form-item>
									
									<el-form-item label="">
										<filelist :datas="ruleForm.related_files"></filelist>
										<el-upload style="margin-top: 10px;"
										  class="upload-demo"
										  :action="$config.uploadUrl"
										  :show-file-list="false"
										  :on-success="uploadFile"
										  multiple>
										  <el-button size="small" type="primary"><!-- 点击上传 -->{{$t('job.DJSC')}}</el-button>
										  
										</el-upload>
									</el-form-item>
									
									<el-form-item :label="$t('job.GZJJ')" prop="job_desc">
										<el-input
										  type="textarea"
										  :rows="4" maxlength="1000" show-word-limit
										  :placeholder="$t('QSR')"
										  v-model="ruleForm.job_desc">
										</el-input>
									</el-form-item>
									<el-form-item :label="$t('job.GZSM')" prop="job_details" >
										<quill-editor
										      ref="myQuillEditor"
										      v-model="ruleForm.job_details"
										      :options="editorOption"
										      @click.native="handleEditAble"
											  
										  />
										 <el-upload
										 	style="display: none;"
										   class="avatar-uploader"
										   id="imguploader"
										   :action="$config.uploadUrl"
										   :show-file-list="false"
										   :on-success="upload_img"
										   list-type="picture-card"
										   >
										    <i class="el-icon-plus"></i>
										 </el-upload>
										
										  <!-- <quill-editor
										        :content="content"
										        :options="editorOption"
										        @change="onEditorChange($event)"
										    /> -->
											
									</el-form-item>
									<el-form-item>
										<el-button type="primary" @click="submitForm('ruleForm')"><!-- 确认 -->{{$t('CONFIRM')}}</el-button>
										<el-button @click="back"><!-- 取消 -->{{$t('CANCEL')}}</el-button>
									</el-form-item>
								</el-form>
							</div>
						
						</div>
					</div>
					
				</div>
				
			</div>

			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<skills ref="skills"></skills>
	</div>
</template>

<script>
	const toolbarOptions = [
	  ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
	  ['blockquote', 'code-block'], //引用，代码块
	  [{ 'header': 1 }, { 'header': 2 }], // 几级标题
	  [{ 'list': 'ordered' }, { 'list': 'bullet' }], // 有序列表，无序列表
	  [{ 'script': 'sub' }, { 'script': 'super' }], // 下角标，上角标
	  [{ 'indent': '-1' }, { 'indent': '+1' }], // 缩进
	  [{ 'direction': 'rtl' }], // 文字输入方向
	  [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
	  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],// 标题
	  [{ 'color': [] }, { 'background': [] }], // 颜色选择
	  [{ 'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }],// 字体
	  [{ 'align': [] }], // 居中
	  ['clean'], // 清除样式,
	  ['link', 'image'], // 上传图片、上传视频
	];
	import skills from '@/components/skills.vue';
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import { quillEditor } from 'vue-quill-editor';
	import Homeright from '../../components/home/home_right.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	import filelist from '@/components/filelist.vue';
	export default {
		components: {
			Homeright,
			quillEditor,
			filelist,skills
		},
		
		data() {
			return {
				// 富文本
				content: '',
				editorOption: {
				       
				        theme: 'snow', //主题 snow/bubble
				        modules: {
				          history: {
				            delay: 1000,
				            maxStack: 50,
				            userOnly: false
				          },
				          toolbar: {
				            container: toolbarOptions,
				            handlers: {
				              image: function (value) {
				                if (value) {
				                  // 调用element的图片上传组件
				                  document.querySelector('#imguploader input').click();
				                } else {
				                  this.quill.format('image', false);
				                }
				              }
				            }
				          }
				        }
				      },
				
				
				
				
				
				citydata:{},//省市区数据
				
				
				skillList:[],//业务技能数据
				salaryUnit:[],
				jobBelong:[],
				payMethod:[],
				operTime:[],
				jobTime:[],
				jobLocation:[],
				downloadMethod:[],
				fastMethod:[],
				tripMethod:[],
				jobMethod:[],
				salary_unit:[],
				jobType:[],
				jobNature:[],
				cateList:[],//岗位
				timeUnit:[],
				ruleForm:{
				  job_id: '',
				  job_name: '',
				  cate_first_id:'',
				  cate_sec_id:'',
				  cate_third_id:'',
				  skills: [],
				  job_belong_method: 0,
				  entrust_name: '',
				  pay_method: 0,
				  salary_unit: '',
				  salary_low: '',
				  salary_high: '',
				  oper_time_method:'0',
				  oper_time_val: '',
				  job_time_method: 0,
				  job_time_val: '',
				  job_location_method: 0,
				  cityname:'',
				  province: '',
				  city: '',
				  area: '',
				  location: '',
				  address: '',
				  job_method: 0,
				  trip_method: 0,
				  job_num_people: '',
				  fast_method: 0,
				  job_nature: '',
				  job_end_time: '',
				  download_method: 0,
				  related_files: [],
				  job_desc: '',
				  job_details: '',
				  
				  
				},
				rules: {
					
					cate_first_id: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					job_name: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					entrust_name: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					oper_time_val: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					salary_low: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					salary_high: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					job_time_val: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
				
					job_num_people: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					job_end_time: [{required: true,trigger: 'blur',message:this.$t('QXZ')}],
					skills: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					
					location: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					address: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					job_desc: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					job_details: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					
					cityname: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
				},
				
			};
		},
		created() {
			if(this.$local.get('lang')){
				this.lang=this.$local.get('lang');
			}
			this.getCitys();
			// if(this.lang=='jp'){
			// 	this.getCitys();
			// }else{
			// 	this.citydata=require('@/assets/js/city.json'); // 直接引入json文件;
			// }		
			this.getSkill();
			this.getCate();
			this.getUnit();
			if(this.$route.query.job_id){
				this.ruleForm.job_id=this.$route.query.job_id;
				this.editJob();
			}
			
		},
		watch:{
			'ruleForm.oper_time_method':{
				handler:function(){
					
				}
			}
		},
		mounted() {
		    
		},
		computed: {
		    editor() {
		      return this.$refs.myQuillEditor.quill;
		    }
		  },
		methods:{
			upload_img(res){
				console.log(res);
				// 获取富文本组件实例
				      let quill = this.$refs.myQuillEditor.quill;
				      // 如果上传成功
				      if (res) {
				        // 获取光标所在位置
				        let length = quill.getSelection().index;
				        // 插入图片，res为服务器返回的图片链接地址
				        quill.insertEmbed(length, 'image', res.data.Location);
				        // 调整光标到最后
				        quill.setSelection(length + 1);
				      } else {
				        // 提示信息，需引入Message
				        this.$message.error('图片插入失败！');
				      }
			},
			async openSkill(){
				let res=await this.$refs.skills.open(this.ruleForm.skills);
				this.ruleForm.skills=res;
			},
			link(n){
				this.$router.push({name:'work',params:{status:n}});
			},
			//back
			back(){
				this.$router.go(-1);
			},
			//富文本
			handleEditAble() {
			  this.$refs.myQuillEditor.quill.enable(true);
			  this.$refs.myQuillEditor.quill.focus();			  
			},
			
			//获取数据
			async editJob(id){
				let res=await this.$request.get('/api/job/getJob',{job_id:this.ruleForm.job_id});
				let $data=res.data;
				$data.oper_time_method=$data.oper_time_method+'';
				for(let i in this.ruleForm){					
					if($data[i]){
						this.ruleForm[i]=$data[i];
					}
				}	
				this.$refs.myQuillEditor.quill.enable(false);
				this.ruleForm.cityname=[res.data.province,res.data.city];
				
				// if(this.ruleForm.oper_time_method==1){
				// 	this.ruleForm.oper_time_val=this.ruleForm.oper_time_val.split(' ~ ');
				// }
			},
			
			
			//选择工作类目
			select_cate(val){
				
				this.ruleForm.cate_first_id=val[0];
				this.ruleForm.cate_sec_id=val[1];
				this.ruleForm.cate_third_id=val[2];
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');				
				this.salaryUnit=res.data.salary_unit.data;
				this.jobBelong=res.data.job_belong_method.data;
				this.payMethod=res.data.pay_method.data;
				this.operTime=res.data.oper_time_method.data;
				this.jobTime=res.data.job_time_method.data;
				this.jobLocation=res.data.job_location_method.data;
				this.tripMethod=res.data.trip_method.data;
				this.fastMethod=res.data.fast_method.data;
				this.downloadMethod=res.data.download_method.data;
				this.salaryUnit=res.data.salary_unit.data;
				this.jobNature=res.data.job_nature.data;
				this.jobMethod=res.data.job_method.data;
				this.timeUnit=res.data.oper_time_method.data;
			},
			//获取岗位数据
			async getCate(){
				let res=await this.$request.get('/api/setjobs/getAllCateList');
				this.cateList=res.data.records;
			},
			  //保存工作
			async submitForm(){
				// console.log(this.ruleForm);
				let data=JSON.parse(JSON.stringify(this.ruleForm));
				delete data.cityname;
				// if(data.oper_time_method==1){
				// 	data.oper_time_val=data.oper_time_val.join(' ~ ');
				// }
				console.log(data);
				if(data.pay_method==1){
					data.salary_low=data.salary_high;
				}
				//return;
				let url='/api/job/saveJob';
				if(this.ruleForm.job_id!=''){
					url='/api/job/updateJob';
				}
				this.$refs['ruleForm'].validate(async (valid) => {
				  if (valid) {					
					this.$request.post(url,data).then((res)=>{
						this.$message({
							message:res.message,
							type:'success'
						});
						
						this.back();
						
					}).catch(()=>{
						
					});					 
				  } else {
					this.$message({
						message:this.$t('QTXWZXX'),
						type:'warning'
					});
				  }
				});
			},
			//上传文件
			uploadFile(response, file, fileList){
				
				let files=JSON.parse(JSON.stringify(this.ruleForm.related_files));
				files.push({name:file.name,url:response.data.Location});
				this.ruleForm.related_files=files;
				//console.log(this.ruleForm.related_files);
				//console.log(fileList);
				
			},
			//获取省市区数据
			async getCitys(){
				let res=await this.$request.get('/api/sysdict/getJapanCityCode');
				this.citydata=res.data.records;
			},
			//选择省市区
			selectCity(val){
				console.log(val);
				this.ruleForm.province=val[0];
				this.ruleForm.city=val[1];
				if(val.length>2){
					this.ruleForm.area=val[2];
				}else{
					this.ruleForm.area='';
				}
				console.log(this.ruleForm);
				//this.$refs.ruleForm.$emit('el.form.change');
			},
			
			//获取业务技能数据
			async getSkill(){
				let res=await this.$request.get('/api/setjobs/getAllSkillList');
				this.skillList=res.data.records;
			},
			//选择业务技能
			selectSkill(val){				
				if(this.ruleForm.skills.indexOf(val)==-1){
					this.ruleForm.skills.push(val);
				}
				console.log(this.ruleForm.skills);
			},
		}
	};
</script>

<style lang="less" scoped>
	.jfgroup{background-color: #f8f8f8;border-radius: 10px;padding: 15px 25px;margin: 15px 0;
		&:first-child{margin-top: 0;}
		.row{display: flex;align-items: flex-start;margin-top: 25px;
			&:first-child{margin-top: 0;}
			.name{margin-right: 15px;color: #000;}
		}
		.filerow{margin-top: 10px;
			&:first-child{margin-top: 0;}
		}
		.filename{display: flex;flex-wrap: wrap;box-sizing: border-box; min-height: 40px;padding: 10px;background-color: #fff;border-radius: 4px;line-height: 1.3;}
		.downbtn{background-color: #567BB6;border-color: #567BB6;}
		
	}
	.jfooter{
		.btn1{width: 200px;height: 60px;display: flex;align-items: center;justify-content: center;border:1px solid #567BB6;border-radius: 8px;font-size: 16px;cursor: pointer;}
		.btn2{flex:1;height: 60px;display: flex;flex-direction: column; align-items: center;justify-content: center;background-color: #567BB6;border-radius: 8px;font-size: 16px;cursor: pointer;color: #fff;margin-left: 15px;}
	}
	.ql-container.ql-snow{
	        line-height: normal !important;
	        height: 200px !important;
	        font-size:14px;
	}
	.work {
		min-height: 100%;
		background-color: #212121;
		.home_center{
			display: flex;
			justify-content: flex-end;
		}
		.center_left {
			color: #fff;
			width: 1190px;
			margin-right: 25px;
			.left_top{
				width: 1190px;
				display: flex;
				align-items: center;
				
				background-color: #000000;
				padding: 15px;
				box-sizing: border-box;
				.el-input__inner{color:#fff;}
				.tablink{color:#567BB6;font-size: 14px;}
				.top_name{
					margin-right: 50px;
					font-size: 21px;
					font-weight: bold;
				}
				.top_input{
					width: 530px;
				}
				.el-input__inner{
					background-color: #000000;
					height: 34px;
				}
				.top_btn{
					.btn2{
						background-color: #000000;
						color: #979797;
						border: 1px solid #979797;
					}
					.btn1{
						background-color: #567BB6;
						
						border-color: #567BB6;
					}
					.top_el_btn{
						background-color: #567BB6;
						border: none;
					}
				}
			}
			.left_box{
				margin-top: 25px;
				display: flex;
				.left_left{
					display: flex;
					flex-direction: column;
					width: 286px;
					margin-right: 25px;
					.mt20{
						margin-top: 20px;
					}
					.btn1{
						width: 286px;
						background-color: #567BB6;
						border: 1px solid #567BB6;
					}
					.btn2:hover{
						width: 286px;
						background-color: #567BB6;
						border: 1px solid #567BB6;
						
					}
					.btn2{
						width: 286px;
						background-color: #212121;
						border: 1px solid #567BB6;
						color: #fff;
					}
					.el-button{
						margin-left: 0;
						margin-bottom: 12px;
					}
				}
				.left_center{
					width: 878px;
					.center_box{
						.box_list{
							padding: 20px;
							background-color: #000000;
							margin-bottom: 20px;
							.list_title{
								display: flex;
								align-items: center;
								margin-bottom: 20px;
								span{
									font-size: 12px;
									margin-left: 10px;
									flex-shrink: 0;
								}
							}
							.list_plan{
								display: flex;
								align-items: center;
								justify-content: space-between;
								background-color: #111215;
								padding: 10px;
								margin-bottom: 10px;
								position: relative;
								.wcbtn{position: absolute;left: 0;top: 0;height: 20px;display:flex;align-items: center;padding:0 10px; background-color: #B65664;border-radius:20px;font-size: 12px;color: #fff;cursor: pointer;}
								.plan_user{
									display: flex;
									align-items: center;
									flex-direction: column;
									.user_img{
										width: 43px;
										height: 43px;
										border-radius: 5px;
										margin-bottom: 10px;
									}
								}
								.plan_menu{
									font-size: 12px;
									display: flex;
									align-items: center;
									flex-direction: column;
									.status{display: flex;align-items: center;justify-content: center;width: 110px; height: 30px;border:1px solid #567BB6;border-radius: 28px;box-sizing: border-box;text-align: center;
										&.active{background-color: #567BB6;color:#fff;border-width: 3px;}
										.date{flex:1;}
									}
									.menu_name{
										margin-top: 10px;
									}
									
								}
								.last_btn{
									display: flex;
									flex-direction: column;
									.el-button{
										margin-left: 0;
										margin-bottom: 10px;
										background-color: #567BB6;
										border: #567BB6;
										color: #fff;
									}
								}
							}
							.list_menu{
								display: flex;
								align-items: center;
								justify-content: space-between;
								.menu{
									display: flex;
									align-items: center;
									font-size: 12px;
									.menu_box{
										width: 129px;
										height: 26px;
										border-radius: 7px;
										border: 1px solid #567BB6;
										text-align: center;
										line-height: 26px;
									}
								}
							}
							.list_group{display: flex;flex-wrap: wrap;margin:-5px;}
							.list_box{
								width:calc(50% - 10px);
								background-color: #16171C;
								padding: 15px;
								box-sizing: border-box;
								margin: 5px;
								color: #fff;
								.box_user{
									display: flex;
									align-items: center;
									justify-content: space-between;
									margin-bottom: 10px;
									.user{
										width: 43px;
										height: 43px;
										border-radius: 5px;
										margin-right: 20px;
									}
									.user_name{
										width: 580px;
										font-size: 14px;
										display: flex;
										align-items: center;
									}
									.user_right{
										font-size: 12px;
										color: #567BB6;
									}
									.el-button{
										background-color: #567BB6;
										border: 1px solid #567BB6;
										color: #fff;
									}
								}
								.box_form{
									.form_list{
										display: flex;
										align-items: center;
										margin-bottom: 10px;
										.list_name{
											width: 119px;
											margin-right: 10px;
											text-align: right;
											font-size: 14px;
										}
										.el-input{
											width: 429px;
											margin-right: 10px;
										}
										.el-input__inner{
											
											color: #fff;
											font-size: 12px;
											background-color: #000;
											border: 1px solid #567BB6;
										}
										.list_center{
											width: 429px;
											margin-right: 10px;
											border-bottom: 1px solid #979797;
											color: #fff;
											line-height: 35px;
											font-size: 12px;
										}
										.el-button{
											background-color: #567BB6;
											border: 1px solid #567BB6;
											color: #fff;
										}
									}
									.form_time{
										display: flex;
										align-items: center;
										justify-content: space-between;
										margin-bottom: 10px;
										.time{
											width: 50%;
											//color: #567BB6;
											font-size: 12px;
											display: flex;
											align-items: center;
										}
										.el-input{
											margin-left: 10px;
											.el-input__inner{
												border: none;
												background-color: #000;
												color: #fff;
											}
										}
									}
									.form_msg{
										color: #fff;
										font-size: 12px;
										line-height: 1.3;
										margin-top: 20px;
										background-color: #000;padding: 10px;border-radius: 6px;
										.el-textarea__inner{padding: 0; border: none;color: #fff;background: none;}
									}
									.last_btn{
										margin-top: 20px;
										background-color: #567BB6;
										border: 1px solid #567BB6;
										color: #fff;
									}
								}
							}
						
						}
					}
				
					.add_work{
						width: 878px;
						background-color: #FFFFFF;
						padding: 50px 30px;
						box-sizing: border-box;
						border-radius: 12px;
						color: #212121;
					}
					.work_center{
						color: #2B2B2A;
						display: flex;
						align-items: center;
						span{
							margin: 10px;
						}
						.el-input{
							width: 100px;
							
						}
					}
				}
				
			}
		}
		
		
		.center_people {
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0;
			

		}
	}
</style>

<template>
	<div>
		<el-dialog :title="$t('QXZ')" :visible.sync="show" width="30%"  @close="close" >
			<div class="skbd">
				<template v-for="item in list">
					<div class="group" v-if="item.children.length>0">
						<div class="title">{{item.label}}</div>
						<div class="child">
							<div v-for="sitem in item.children" :key="sitem.value" class="item" :class="{active:vals.indexOf(sitem.label)>-1}" @click="selectSkill(sitem.label)">{{sitem.label}}</div>
						</div>
					</div>
				</template>
			</div>
			<span slot="footer" class="dialog-footer">
			    <el-button @click="show = false">{{$t('CANCEL')}}</el-button>
			    <el-button type="primary" @click="confirm">{{$t('CONFIRM')}}</el-button>
			  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default ({
		data() {
			return {
				show:false,
				list:[],
				vals:[]
			};
		},
		created(){
			this.getSkill();			
		},
		methods: {			
			//选择业务技能
			selectSkill(val){				
				if(this.vals.indexOf(val)==-1){
					this.vals.push(val);
				}else{
					this.vals.splice(this.vals.indexOf(val),1);
				}
				//console.log(this.vals);
			},
			//获取业务技能数据
			async getSkill(){
				let res=await this.$request.get('/api/setjobs/getAllSkillList');				
				this.list=res.data.records;
			},
			open(val){
				this.show=true;
				this.vals=JSON.parse(JSON.stringify(val));
				return new Promise((resolve,reject)=>{
					this.$once('confirm',()=>{
						resolve(this.vals);
					});
					this.$once('cancel',()=>{
						reject(false);
					});
				});
			},
			close:function(){
				this.show = false;
				this.$emit('change', this.vals);				
			},
			confirm() {
				this.$emit('confirm');
				if(!this.stopClose){
					this.close();
				}				
			},
			cancel() {				
				this.$emit('cancel');
				this.close();
			},
		},
		
	});
</script>

<style scoped lang="less" scoped>
	.group{padding: 15px 0;
		&:first-child{padding-top: 0;}
	}
	.title{font-size: 14px;font-weight: bold;color: #000;}
	.skbd{max-height: 400px;overflow-y: auto;}
	.child{display: flex;flex-wrap: wrap;
		.item{height: 32px;border:1px solid #ddd;border-radius: 5px;padding:0 15px;margin:10px 10px 0 0;text-align: center;display: flex;align-items: center;justify-content: center;cursor: pointer;color: #666;
			&.active{background-color: #567BB6;border-color: #567BB6;color: #fff;}
		}
	}
</style>